import React from 'react';
import Layout from "../page-components/layout/layout";
import { graphql, Link } from "gatsby";

const CategoriesListPage = ( { data } ) => {

    const categories = data.allShopifyCollection.nodes;

    return <Layout>
        <div className="container">
            <div className="row bg-white py-5">
                <div className="col-12">
                    <h2 className={"mb-2"}>Sub-Kategorien</h2>

                    {
                        categories.map((c) => {
                            return <div className={"my-5"}>
                                <p>{c.title}</p>
                                <Link to={"/kategorie/" + c.handle}>Mehr</Link>
                            </div>
                        })
                    }


                </div>
            </div>
        </div>
    </Layout>;

}

export default CategoriesListPage;


export const query = graphql`
    query CATEGORIES_LIST_PAGE {
        allShopifyCollection {
            nodes {
              id
              descriptionHtml
              handle
              title
            }
          }
    }
`;
